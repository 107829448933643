import * as React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(200px, 1200px) auto;
`;

export const TitleRow = styled.div`
  background-color: #282625;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Col = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 4%;
  padding-bottom: 4%;
  color: var(--white);

  @media (max-width: 980px) {
    width: initial;
    padding-right: 10%;
    padding-left: 10%;

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const Divider = styled.div`
  & {
    border: 1px solid var(--white);
    display: inline-block;
    width: 12%;
    border-width: 2px;
  }
`;

export default function Banner({title, description, colour}: {title: string, description?: string, colour: string}) {
  return (
    <Container>
    <aside style={{ background: colour }}></aside>
    <TitleRow>
      <Col>
        <h2>{title}</h2>
        <Divider />
        <p>{description}</p>
      </Col>
    </TitleRow>
    <aside style={{ background: colour }}></aside>
  </Container>
  )
}
