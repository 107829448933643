import * as React from "react";

import Banner from "../components/Layout/Banner";
import SEO from "../components/SEO";
import { StaticImage } from "gatsby-plugin-image";
import TwoImages from "../components/Layout/TwoImages";

export default function FinishesPage() {
  const finishes = {
    artBeton: "Art Beton",
    marmurino: "Marmurino",
    metallicPain: "Metallic Pain",
    rust: "Rust",
    travertine: "Travertino",
    colour: "#282625",
  };

  const twoImages = {
    background: "#282625",
  };
  return (
    <>
      <SEO title="Finishes" />
      <Banner title={finishes.artBeton} colour={finishes.colour} />

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_6_600x800.jpg"
          placeholder="blurred"
          width={600}
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_2_800x600.jpg"
          placeholder="blurred"
          width={600}
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_1_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_5_800x600.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_3_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          placeholder="blurred"
          src="../images/finishes-page/art beton/art_beton_4_600x889.jpg"
        />
      </TwoImages>

      <Banner title={finishes.marmurino} colour={finishes.colour} />
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="marmurino finish sample"
          src="../images/finishes-page/marmurino/marmurino_1_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="marmurino finish sample"
          src="../images/finishes-page/marmurino/marmurino_2_800x600.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <Banner title={finishes.metallicPain} colour={finishes.colour} />
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_3_600x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_2_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/matellic_paint_1_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_4_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_5_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_6_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_7_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="metallic paint finish sample"
          src="../images/finishes-page/metallic paint/metallic_paint_8_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <Banner title={finishes.rust} colour={finishes.colour} />
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_1_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_2_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_3_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_4_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_5_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="rust finish sample"
          src="../images/finishes-page/rust/rust_6_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <Banner title={finishes.travertine} colour={finishes.colour} />
      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_1_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_2_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_5_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_4_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_6_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_7_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_3_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_9_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_8_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_10_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_11_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_12_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>

      <TwoImages colour={twoImages.background}>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_13_600x800.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="travertino finish sample"
          src="../images/finishes-page/travertino/travertino_14_600x800.jpg"
          placeholder="blurred"
        />
      </TwoImages>
    </>
  );
}
